<template>
  <v-container class="mt-8">
    <h4 class="text-center text-md-h5 text-xs-body-2 font-weight-bold font-weight-black text-wrap">
      Click the Start Button of any Symptom to begin
    </h4>
    <v-card class="mx-lg-auto mt-6" width="1000px">
      <v-card-title class="align-start">
        <v-row>
          <v-col cols="12" sm="3" class="mt-5"> </v-col>
          <v-col cols="12" sm="9" class="d-flex justify-end">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              class="ml-auto mr-3"
              label="Search Symptoms"
              hide-details
              single-line
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-divider class="mb-5" />
        <v-row>
          <v-col v-for="(link, key) in filterModule" :key="key" cols="12" sm="12" md="4">
            <v-card class="mb-4">
              <v-card-title>
                {{ link.name | uppercase }}
              </v-card-title>
              <v-card-subtitle>{{ link.description }}</v-card-subtitle>
              <v-card-actions class="justify-center">
                <v-btn color="primary" class="justify-center">
                  <router-link
                    :to="`module/${link.id}`"
                    class="d-flex text-decoration-none"
                    style="color: #fff !important"
                  >
                    Start
                  </router-link>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable-next-line object-curly-newline */
import { mapState } from "vuex";
import { mdiMagnify } from "@mdi/js";
import moodule from "@/services/moduleService";

// import moodule from "@/services/moduleService";

export default {
  name: "Home",
  data: () => ({
    title: "Modules",
    search: null,
    moodles: [],
    icons: {
      mdiMagnify,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    filterModule() {
      if (this.search) {
        const filtered = this.moodles.filter(
          item =>
            // eslint-disable-next-line implicit-arrow-linebreak
            item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            item.description.toLowerCase().includes(this.search.toLowerCase()),
        );

        return filtered;
      }

      return this.moodles;
    },
  },
  created() {
    this.getModules();
  },
  methods: {
    async getModules() {
      await moodule.getModules().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data();
          data.id = doc.id;
          this.moodles.push(data);
        });
      });
    },
  },
};
</script>
